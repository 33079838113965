/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'

import TextButton from '../TextButton/Loadable'
import Button from '../Button/Loadable'
import TextInput from '../TextInput/Loadable'
import ModalOverlay from '../ModalOverlay/Loadable'
import CameraRowCard from '../CameraRowCard/Loadable'
import ModalNotification from '../ModalNotification/Loadable'
import LabelInput from '../LabelInput/Loadable'
import CheckboxComponent from '../CheckboxComponent/Loadable'

import './styled.scss'
import MESSAGE from './message'

const DND_SITE_EDITOR_VALUE = 'DND_SITE_EDITOR_VALUE'

class AreaRowCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showDeleteAreaModal: false,
      showCreateCameraModal: false,
      areaNameInput: '',
      isAreaNameValid: undefined,
      showEditAreaNameModal: false,
      cameraDetailData: {
        camera_name: '',
        rtsp_address: '',
        latitude: '',
        longtitude: '',
        reading_fps: '',
        scale_factor: '',
        motion_threshold: '',
        number_grids_threshold: '',
        resolution_width: '',
        resolution_height: '',
        violation_time: '',
        violation_count: '',
        is_vehicle_detection: false
      },
      is_camera_name_valid: undefined,
      is_rtsp_address_valid: undefined,
      is_latitude_valid: undefined,
      is_longtitude_valid: undefined,
      is_reading_fps_valid: undefined,
      is_scale_factor_valid: undefined,
      is_motion_threshold_valid: undefined,
      is_violation_count_valid: undefined,
      is_violation_time_valid: undefined,
      is_number_grids_threshold_valid: undefined,
      is_resolution_width_valid: undefined,
      is_resolution_height_valid: undefined
    }
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language]
    return msg[property]
  }

  setShowDeleteAreaModal = () => {
    this.setState({
      showDeleteAreaModal: true
    })
  }

  handleAddCameraBtnClicked = () => {
    this.setState({
      showCreateCameraModal: true,
      cameraDetailData: {
        camera_name: '',
        rtsp_address: '',
        latitude: '',
        longtitude: '',
        reading_fps: '',
        scale_factor: '',
        motion_threshold: '',
        number_grids_threshold: '',
        violation_time: '60',
        violation_count: '1',
        resolution_width: '1920',
        resolution_height: '1080',
        is_vehicle_detection: false
      },
      is_camera_name_valid: undefined,
      is_rtsp_address_valid: undefined,
      is_latitude_valid: undefined,
      is_longtitude_valid: undefined,
      is_reading_fps_valid: undefined,
      is_scale_factor_valid: undefined,
      is_violation_count_valid: undefined,
      is_violation_time_valid: undefined,
      is_motion_threshold_valid: undefined,
      is_number_grids_threshold_valid: undefined,
      is_resolution_width_valid: undefined,
      is_resolution_height_valid: undefined
    })
  }

  getHeaderOption() {
    const primary = this.props.listCamera.length >= 5 ? false : true
    const onClick = this.props.listCamera.length >= 5 ? null : this.handleAddCameraBtnClicked
    return (
      <div className="area-row-header-option-container">
        <TextButton
          className="area-row-header-btn area-row-header-option-btn"
          label={this.getMessage('add_camera_btn_txt')}
          onClick={onClick}
          primary={primary}
        />
      </div>
    )
  }

  getHeaderRemove() {
    return (
      <div className="area-row-header-remove-container">
        <TextButton className="area-row-header-btn" label={this.getMessage('remove_btn_txt')} onClick={this.setShowDeleteAreaModal} danger />
      </div>
    )
  }

  handleAreaNameChanged(e) {
    this.setState({
      areaName: e.target.value
    })
  }

  setShowEditAreaNameModal = () => {
    this.setState({
      showEditAreaNameModal: true,
      areaNameInput: this.props.name,
      isAreaNameValid: true
    })
  }

  getAreaHeaderSection() {
    return (
      <div className="area-row-header-container">
        <div className="area-row-header-name-container">
          <div className="area-row-header-name prevent-text-overflow">{this.props.name}</div>
          <TextButton className="area-row-header-btn" label={this.getMessage('edit_btn_txt')} onClick={this.setShowEditAreaNameModal} primary />
        </div>
        {this.getHeaderOption()}
        {this.getHeaderRemove()}
      </div>
    )
  }

  getCameraListSection() {
    const listCamera = this.props.listCamera.map((cameraData) => {
      return (
        <CameraRowCard
          key={cameraData.camera_id}
          id={cameraData.camera_id}
          cameraData={cameraData}
          name={cameraData.name}
          siteID={this.props.siteID}
          status={cameraData.camera_status.toUpperCase()}
          areaID={this.props.areaID}
          speakerData={cameraData.speaker}
          currentUser={this.props.currentUser}
          onAddCameraBetweenArea={this.props.onAddCameraBetweenArea}
          onDeleteCamera={this.props.onDeleteCamera}
          onUpdateCameraData={this.props.onUpdateCameraData}
          onCreateSpeaker={this.props.onCreateSpeaker}
          onUpdateSpeaker={this.props.onUpdateSpeaker}
          onDeleteSpeaker={this.props.onDeleteSpeaker}
          isRecalculatingZone={this.props.isRecalculatingZone}
        />
      )
    })
    return <div className="area-list-camera-container">{listCamera}</div>
  }

  getAreaRowClassName() {
    let className = 'area-row-container'
    if (this.props.isOver) {
      className += ' is-over'
    }
    return className
  }

  handleCloseDeleteAreaModal = () => {
    this.setState({ showDeleteAreaModal: false })
  }

  handleDeleteAreaBtnClicked = () => {
    this.handleCloseDeleteAreaModal()
    this.props.onDeleteArea(this.props.areaID, this.props.siteID)
  }

  getDeleteAreaModal() {
    let output = null
    if (this.state.showDeleteAreaModal) {
      output = (
        <ModalNotification
          title={this.getMessage('delete_area_dialog_title')}
          message={this.getMessage('delete_area_dialog_message')}
          onSecondaryButtonClick={this.handleCloseDeleteAreaModal}
          onPrimaryButtonClick={this.handleDeleteAreaBtnClicked}
          onOverLayClose={this.handleCloseDeleteAreaModal}
          secondaryButtonText={this.getMessage('delete_area_cancel_btn_txt')}
          primaryButtonText={this.getMessage('delete_area_delete_btn_txt')}
        />
      )
    }
    return output
  }

  handleCloseCreateCameraModal = () => {
    this.setState({
      showCreateCameraModal: false,
      cameraDetailData: {
        camera_name: '',
        rtsp_address: '',
        latitude: '',
        longtitude: '',
        reading_fps: '',
        scale_factor: '',
        motion_threshold: '',
        number_grids_threshold: '',
        resolution_width: '',
        resolution_height: '',
        violation_count: '',
        violation_time: '',
        is_vehicle_detection: false
      },
      is_camera_name_valid: undefined,
      is_rtsp_address_valid: undefined,
      is_latitude_valid: undefined,
      is_longtitude_valid: undefined,
      is_reading_fps_valid: undefined,
      is_scale_factor_valid: undefined,
      is_motion_threshold_valid: undefined,
      is_violation_count_valid: undefined,
      is_violation_time_valid: undefined,
      is_number_grids_threshold_valid: undefined,
      is_resolution_width_valid: undefined,
      is_resolution_height_valid: undefined
    })
  }

  isLocationFormatValid = (value) => {
    const locationRegex = /^-?\d*(\.\d+)?$/
    return locationRegex.test(value)
  }

  isNumberValid = (value) => {
    const resolutionRegex = /^\d{1,10}$/
    return resolutionRegex.test(value)
  }

  isViolationCountAndTimeValid = (value) => {
    const regexText = /^0*[1-9]\d*$/
    return regexText.test(value)
  }

  _isCameraDataValid = () => {
    const is_camera_name_valid = this.state.cameraDetailData.camera_name.trim().length > 0
    const is_rtsp_address_valid = this.state.cameraDetailData.rtsp_address.trim().length > 0
    const is_latitude_valid =
      this.state.cameraDetailData.latitude.trim().length > 0 && this.isLocationFormatValid(this.state.cameraDetailData.latitude.trim())
    const is_longtitude_valid =
      this.state.cameraDetailData.longtitude.trim().length > 0 && this.isLocationFormatValid(this.state.cameraDetailData.longtitude.trim())
    const is_reading_fps_valid = this.isNumberValid(this.state.cameraDetailData.reading_fps.trim())
    const is_scale_factor_valid = this.isNumberValid(this.state.cameraDetailData.scale_factor.trim())
    const is_motion_threshold_valid = this.isNumberValid(this.state.cameraDetailData.motion_threshold.trim())
    const is_number_grids_threshold_valid = this.isNumberValid(this.state.cameraDetailData.number_grids_threshold.trim())
    const is_violation_count_valid = this.props.currentUser.manager
      ? true
      : this.isViolationCountAndTimeValid(this.state.cameraDetailData.violation_count.trim())
    const is_violation_time_valid = this.props.currentUser.manager
      ? true
      : this.isViolationCountAndTimeValid(this.state.cameraDetailData.violation_time.trim())
    const is_resolution_width_valid = this.isNumberValid(this.state.cameraDetailData.resolution_width.trim())
    const is_resolution_height_valid = this.isNumberValid(this.state.cameraDetailData.resolution_height.trim())
    this.setState({
      is_camera_name_valid,
      is_rtsp_address_valid,
      is_latitude_valid,
      is_longtitude_valid,
      is_reading_fps_valid,
      is_scale_factor_valid,
      is_motion_threshold_valid,
      is_number_grids_threshold_valid,
      is_violation_count_valid,
      is_violation_time_valid,
      is_resolution_width_valid,
      is_resolution_height_valid
    })
    return (
      is_camera_name_valid &&
      is_rtsp_address_valid &&
      is_latitude_valid &&
      is_longtitude_valid &&
      is_reading_fps_valid &&
      is_scale_factor_valid &&
      is_motion_threshold_valid &&
      is_number_grids_threshold_valid &&
      is_violation_count_valid &&
      is_violation_time_valid &&
      is_resolution_width_valid &&
      is_resolution_height_valid
    )
  }

  handleConnectCameraBtnClicked = () => {
    if (this._isCameraDataValid()) {
      const cameraData = Object.assign({}, this.state.cameraDetailData, {
        prev_resolution_width: `${this.state.cameraDetailData.resolution_width}`,
        prev_resolution_height: `${this.state.cameraDetailData.resolution_height}`
      })
      this.props.onCreateNewCamera(cameraData, this.props.areaID, this.props.siteID)
      this.handleCloseCreateCameraModal()
    }
  }

  handleCreateCameraInputChanged = (newValue, property) => {
    this.setState({
      cameraDetailData: Object.assign({}, this.state.cameraDetailData, {
        [property]: property === 'is_vehicle_detection' ? newValue.checked : newValue
      })
    })
  }

  renderViolationCountAndTimeInput = () => {
    let output = null
    if (this.props.currentUser.admin) {
      output = (
        <div className="modal-content-row-container half">
          <TextInput
            mode="material-design"
            type="text"
            label={this.getMessage('camera_detail_violation_period_label_txt')}
            id="edit-violation-time-input"
            placeHolder={this.getMessage('camera_detail_violation_period_placeholder_txt')}
            value={this.state.cameraDetailData.violation_time}
            onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'violation_time')}
            errorText={this.state.is_violation_time_valid === false ? this.getMessage('camera_detail_violation_period_error_txt') : ''}
          />
          <TextInput
            mode="material-design"
            type="text"
            label={this.getMessage('camera_detail_min_violation_count_label_txt')}
            id="edit-violation-count-input"
            placeHolder={this.getMessage('camera_detail_min_violation_count_placeholder_txt')}
            value={this.state.cameraDetailData.violation_count}
            onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'violation_count')}
            errorText={this.state.is_violation_count_valid === false ? this.getMessage('camera_detail_min_violation_count_error_txt') : ''}
          />
        </div>
      )
    }
    return output
  }

  getCreateCameraModal() {
    let output = null
    if (this.state.showCreateCameraModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseCreateCameraModal}>
          <div className="modal-content-container camera-detail" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content-header-container">{this.getMessage('camera_detail_header_title_txt')}</div>
            <div className="modal-content-row-container">
              <TextInput
                mode="material-design"
                type="text"
                label={this.getMessage('camera_detail_rtsp_label_txt')}
                id="edit-rtsp-address-input"
                placeHolder={this.getMessage('camera_detail_rtsp_placeholder_txt')}
                value={this.state.cameraDetailData.rtsp_address}
                onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'rtsp_address')}
                errorText={this.state.is_rtsp_address_valid === false ? this.getMessage('camera_detail_rtsp_error_txt') : ''}
              />
            </div>
            <div className="modal-content-row-container half">
              <div className="full-width">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('camera_detail_title_label_txt')}
                  id="edit-camera-name-input"
                  placeHolder={this.getMessage('camera_detail_title_placeholder_txt')}
                  value={this.state.cameraDetailData.camera_name}
                  onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'camera_name')}
                  errorText={this.state.is_camera_name_valid === false ? this.getMessage('camera_detail_title_error_txt') : ''}
                />
              </div>
              <div className="full-width">
                <LabelInput className="label-material full-width" text={this.getMessage('camera_detail_resolution_label_txt')} />
                <div className="flex">
                  <div className="full-width modal-input-container">
                    <input
                      type="text"
                      onChange={(e) => this.handleCreateCameraInputChanged(e.target.value, 'resolution_width')}
                      placeholder={this.getMessage('camera_detail_width_placeholder_txt')}
                      value={this.state.cameraDetailData.resolution_width}
                    />
                    <div className="validate-error-message">
                      {this.state.is_resolution_width_valid === false ? this.getMessage('camera_detail_resolution_error_txt') : ''}
                    </div>
                  </div>
                  <div className="flex justify-center align-items-center resolution-time-icon">x</div>
                  <div className="full-width modal-input-container">
                    <input
                      type="text"
                      onChange={(e) => this.handleCreateCameraInputChanged(e.target.value, 'resolution_height')}
                      value={this.state.cameraDetailData.resolution_height}
                      placeholder={this.getMessage('camera_detail_height_placeholder_txt')}
                    />
                    <div className="validate-error-message">
                      {this.state.is_resolution_height_valid === false ? this.getMessage('camera_detail_resolution_error_txt') : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-content-row-container half">
              <TextInput
                mode="material-design"
                type="text"
                label={this.getMessage('camera_detail_latitude_label_txt')}
                id="edit-camera-lat-input"
                placeHolder={this.getMessage('camera_detail_latitude_placeholder_txt')}
                value={this.state.cameraDetailData.latitude}
                onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'latitude')}
                errorText={this.state.is_latitude_valid === false ? this.getMessage('camera_detail_latitude_error_txt') : ''}
              />
              <TextInput
                mode="material-design"
                type="text"
                label={this.getMessage('camera_detail_longtitude_label_txt')}
                id="edit-camera-long-input"
                placeHolder={this.getMessage('camera_detail_longtitude_placeholder_txt')}
                value={this.state.cameraDetailData.longtitude}
                onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'longtitude')}
                errorText={this.state.is_longtitude_valid === false ? this.getMessage('camera_detail_longtitude_error_txt') : ''}
              />
            </div>
            <div className="modal-content-row-container half">
              <TextInput
                mode="material-design"
                type="text"
                label={this.getMessage('camera_detail_fps_label_txt')}
                id="edit-reading-fps-input"
                placeHolder={this.getMessage('camera_detail_fps_placeholder_txt')}
                value={this.state.cameraDetailData.reading_fps}
                onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'reading_fps')}
                errorText={this.state.is_reading_fps_valid === false ? this.getMessage('camera_detail_fps_error_txt') : ''}
              />
              <TextInput
                mode="material-design"
                type="text"
                label={this.getMessage('camera_detail_scale_factor_label_txt')}
                id="edit-scale-factor-input"
                placeHolder={this.getMessage('camera_detail_scale_factor_placeholder_txt')}
                value={this.state.cameraDetailData.scale_factor}
                onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'scale_factor')}
                errorText={this.state.is_scale_factor_valid === false ? this.getMessage('camera_detail_scale_factor_error_txt') : ''}
              />
            </div>
            <div className="modal-content-row-container half">
              <TextInput
                mode="material-design"
                type="text"
                label={this.getMessage('camera_detail_motion_threshold_label_txt')}
                id="edit-motion-threshold-input"
                placeHolder={this.getMessage('camera_detail_motion_threshold_placeholder_txt')}
                value={this.state.cameraDetailData.motion_threshold}
                onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'motion_threshold')}
                errorText={this.state.is_motion_threshold_valid === false ? this.getMessage('camera_detail_motion_threshold_error_txt') : ''}
              />
              <TextInput
                mode="material-design"
                type="text"
                label={this.getMessage('camera_detail_number_grid_threshold_label_txt')}
                id="edit-num-grid-input"
                placeHolder={this.getMessage('camera_detail_number_grid_threshold_placeholder_txt')}
                value={this.state.cameraDetailData.number_grids_threshold}
                onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'number_grids_threshold')}
                errorText={
                  this.state.is_number_grids_threshold_valid === false ? this.getMessage('camera_detail_number_grid_threshold_error_txt') : ''
                }
              />
            </div>
            {this.renderViolationCountAndTimeInput()}
            <div className="modal-content-row-container">
              <CheckboxComponent
                id="enable-vehicle-detection-checkbox"
                checked={this.state.cameraDetailData.is_vehicle_detection}
                onChange={(newValue) => this.handleCreateCameraInputChanged(newValue, 'is_vehicle_detection')}
                label={'Enable vehicle detection'}
              />
            </div>
            <Button
              className="full-width"
              id="connect-camera-btn"
              onClick={this.handleConnectCameraBtnClicked}
              text={this.getMessage('camera_detail_connect_btn_txt')}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  handleCloseEditAreaNameModal = () => {
    this.setState({
      showEditAreaNameModal: false,
      areaNameInput: '',
      isAreaNameValid: undefined
    })
  }

  _isAreaNameValid = () => {
    const name = this.state.areaNameInput.trim()
    const isAreaNameValid = name.length > 0
    this.setState({ isAreaNameValid })
    return isAreaNameValid
  }

  handleUpdateAraeNameBtnClicked = () => {
    if (this._isAreaNameValid()) {
      const areaName = this.state.areaNameInput
      this.props.onUpdateAreaName(areaName, this.props.areaID, this.props.siteID)
      this.handleCloseEditAreaNameModal()
    }
  }

  handleAreaNameInputChanged = (e, newValue) => {
    this.setState({
      areaNameInput: newValue
    })
  }

  getEditAreaNameModal() {
    let output = null
    if (this.state.showEditAreaNameModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseEditAreaNameModal}>
          <div className="modal-content-container" onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('area_name_dialog_label_txt')}
                  id="create-site-input"
                  placeHolder={this.getMessage('area_name_dialog_placeholder_txt')}
                  value={this.state.areaNameInput}
                  onChange={this.handleAreaNameInputChanged}
                  errorText={this.state.isAreaNameValid === false ? this.getMessage('area_name_dialog_error_txt') : ''}
                />
              </div>
            </div>
            <Button
              className="full-width"
              id="create-site-save-btn"
              onClick={this.handleUpdateAraeNameBtnClicked}
              text={this.getMessage('save_btn_txt')}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  render() {
    const className = this.getAreaRowClassName()
    const { connectDropTarget } = this.props
    return connectDropTarget(
      <div className={className}>
        {this.getAreaHeaderSection()}
        {this.getCameraListSection()}
        {this.getDeleteAreaModal()}
        {this.getEditAreaNameModal()}
        {this.getCreateCameraModal()}
      </div>
    )
  }
}

AreaRowCard.propTypes = {
  siteID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  areaID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentUser: PropTypes.shape({
    admin: PropTypes.bool,
    email: PropTypes.string,
    firstname: PropTypes.string,
    language: PropTypes.string,
    lastname: PropTypes.string,
    manager: PropTypes.bool,
    token: PropTypes.string
  }),
  name: PropTypes.string.isRequired,
  listCamera: PropTypes.arrayOf(
    PropTypes.shape({
      camera_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      camera_status: PropTypes.string.isRequired
    })
  ).isRequired,
  onAddCameraBetweenArea: PropTypes.func.isRequired,
  onCreateNewCamera: PropTypes.func.isRequired,
  onDeleteArea: PropTypes.func.isRequired,
  onDeleteCamera: PropTypes.func.isRequired,
  onUpdateCameraData: PropTypes.func.isRequired,
  onCreateSpeaker: PropTypes.func.isRequired,
  onUpdateSpeaker: PropTypes.func.isRequired,
  onDeleteSpeaker: PropTypes.func.isRequired,
  isOver: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  onUpdateAreaName: PropTypes.func,
  isRecalculatingZone: PropTypes.bool
}

const dropTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null
    }
    const hoverTargetData = props
    const draggingData = monitor.getItem()
  },

  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      // If you want, you can check whether some nested
      // target already handled drop
      return
    }
    // Obtain the dragged item
    const dropTargetData = props
    return dropTargetData
  }
}

const dropCollect = (connect, monitor) => {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }
}

export default DropTarget(DND_SITE_EDITOR_VALUE, dropTarget, dropCollect)(AreaRowCard)
