const MESSAGE = {
  EN: {
    edit_btn_txt: 'Edit',
    save_btn_txt: 'Save',
    remove_btn_txt: 'Remove',
    cancel_btn_txt: 'Cancel',
    add_camera_btn_txt: 'Add Camera',
    delete_area_dialog_title: 'Delete Area',
    delete_area_dialog_message: 'Are you sure to delete area ?',
    delete_area_delete_btn_txt: 'Delete',
    delete_area_cancel_btn_txt: 'Cancel',
    camera_detail_header_title_txt: 'Camera Details',
    camera_detail_title_label_txt: 'CAMERA TITLE',
    camera_detail_title_placeholder_txt: 'Camera Title',
    camera_detail_title_error_txt: 'Invalid.',
    camera_detail_rtsp_label_txt: 'RTSP ADDRESS',
    camera_detail_rtsp_placeholder_txt: 'RTSP address',
    camera_detail_rtsp_error_txt: 'Invalid.',
    camera_detail_width_placeholder_txt: 'Width',
    camera_detail_height_placeholder_txt: 'Height',
    camera_detail_latitude_label_txt: 'LATITUDE',
    camera_detail_latitude_placeholder_txt: 'Latitude',
    camera_detail_latitude_error_txt: 'Invalid.',
    camera_detail_longtitude_label_txt: 'LONGTITUDE',
    camera_detail_longtitude_placeholder_txt: 'Longtitude',
    camera_detail_longtitude_error_txt: 'Invalid.',
    camera_detail_fps_label_txt: 'READING FPS',
    camera_detail_fps_placeholder_txt: 'Reading FPS',
    camera_detail_fps_error_txt: 'Invalid.',
    camera_detail_scale_factor_label_txt: 'SCALE FACTOR',
    camera_detail_scale_factor_placeholder_txt: 'Scale Factor',
    camera_detail_scale_factor_error_txt: 'Invalid.',
    camera_detail_motion_threshold_label_txt: 'MOTION THRESHOLD',
    camera_detail_motion_threshold_placeholder_txt: 'Motion Threshold',
    camera_detail_motion_threshold_error_txt: 'Invalid.',
    camera_detail_number_grid_threshold_label_txt: 'NUMBER GRIDS THRESHOLD',
    camera_detail_number_grid_threshold_placeholder_txt: 'Number Grids Threshold',
    camera_detail_number_grid_threshold_error_txt: 'Invalid.',
    camera_detail_violation_period_label_txt: 'CASE RESET PERIOD (SECOND)',
    camera_detail_violation_period_placeholder_txt: 'Case Reset Period',
    camera_detail_violation_period_error_txt: 'Invalid',
    camera_detail_min_violation_count_label_txt: 'MINIMUM CASE TO RESET PERIOD',
    camera_detail_min_violation_count_placeholder_txt: 'Minimum Case To Reset Period',
    camera_detail_min_violation_count_error_txt: 'Invalid',
    camera_detail_resolution_label_txt: 'RESOLUTION ( WIDTH x HEIGHT )',
    camera_detail_resolution_error_txt: 'Invalid.',
    camera_detail_connect_btn_txt: 'Connect',
    area_name_dialog_label_txt: 'AREA NAME',
    area_name_dialog_placeholder_txt: 'Area name',
    area_name_dialog_error_txt: 'Invalid name.'
  },
  TH: {
    edit_btn_txt: 'แก้ไข',
    save_btn_txt: 'Save',
    remove_btn_txt: 'ลบ',
    cancel_btn_txt: 'ยกเลิก',
    add_camera_btn_txt: 'เพิ่มกล้อง',
    delete_area_dialog_title: 'ลบพื้นที่',
    delete_area_dialog_message: 'คุณต้องการที่จะลบพื้นที่ใช่หรือไม่ ?',
    delete_area_delete_btn_txt: 'ลบ',
    delete_area_cancel_btn_txt: 'ยกเลิก',
    camera_detail_header_title_txt: 'ข้อมูลกล้อง',
    camera_detail_title_label_txt: 'ชื่อกล้อง',
    camera_detail_title_placeholder_txt: 'ชื่อกล้อง',
    camera_detail_title_error_txt: 'ไม่ถูกต้อง.',
    camera_detail_rtsp_label_txt: 'RTSP ADDRESS',
    camera_detail_rtsp_placeholder_txt: 'RTSP address',
    camera_detail_rtsp_error_txt: 'ไม่ถูกต้อง.',
    camera_detail_width_placeholder_txt: 'กว้าง',
    camera_detail_height_placeholder_txt: 'ยาว',
    camera_detail_latitude_label_txt: 'ละติจูด',
    camera_detail_latitude_placeholder_txt: 'ละติจูด',
    camera_detail_latitude_error_txt: 'ไม่ถูกต้อง.',
    camera_detail_longtitude_label_txt: 'ลองจิจูด',
    camera_detail_longtitude_placeholder_txt: 'ลองจิจูด',
    camera_detail_longtitude_error_txt: 'ไม่ถูกต้อง.',
    camera_detail_fps_label_txt: 'READING FPS',
    camera_detail_fps_placeholder_txt: 'Reading FPS',
    camera_detail_fps_error_txt: 'ไม่ถูกต้อง.',
    camera_detail_scale_factor_label_txt: 'SCALE FACTOR',
    camera_detail_scale_factor_placeholder_txt: 'Scale Factor',
    camera_detail_scale_factor_error_txt: 'ไม่ถูกต้อง.',
    camera_detail_motion_threshold_label_txt: 'MOTION THRESHOLD',
    camera_detail_motion_threshold_placeholder_txt: 'Motion Threshold',
    camera_detail_motion_threshold_error_txt: 'ไม่ถูกต้อง.',
    camera_detail_number_grid_threshold_label_txt: 'NUMBER GRIDS THRESHOLD',
    camera_detail_number_grid_threshold_placeholder_txt: 'Number Grids Threshold',
    camera_detail_number_grid_threshold_error_txt: 'ไม่ถูกต้อง.',
    camera_detail_violation_period_label_txt: 'CASE RESET PERIOD (SECOND)',
    camera_detail_violation_period_placeholder_txt: 'Case Reset Period',
    camera_detail_violation_period_error_txt: 'ไม่ถูกต้อง',
    camera_detail_min_violation_count_label_txt: 'MINIMUM CASE TO RESET PERIOD',
    camera_detail_min_violation_count_placeholder_txt: 'Minimum Case To Reset Period',
    camera_detail_min_violation_count_error_txt: 'ไม่ถูกต้อง',
    camera_detail_resolution_label_txt: 'RESOLUTION ( WIDTH x HEIGHT )',
    camera_detail_resolution_error_txt: 'ไม่ถูกต้อง',
    camera_detail_connect_btn_txt: 'เชื่อมต่อ',
    area_name_dialog_label_txt: 'ชื่อพื้นที่',
    area_name_dialog_placeholder_txt: 'ชื่อ',
    area_name_dialog_error_txt: 'ชื่อไม่ถูกต้อง'
  }
}

export default MESSAGE
